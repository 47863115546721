import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Taiwan",
  "subtitle": "November 2013",
  "category": "Asia"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <p>{`It's not an exaggeration to say that all I did was eat a lot. Tainan is my favorite!`}</p>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Youtiao"
        }}>{`油條`}</a>{` - Chinese fried dough`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Lo_bah_png"
        }}>{`滷肉飯`}</a>{` - Taiwanese braised pork over rice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Cotton_candy"
        }}>{`綿菓子`}</a>{` - Flower cotton candy is cool to watch`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Stinky_tofu"
        }}>{`臭豆腐`}</a>{` - Stinky Tofu`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://zh.wikipedia.org/zh-hans/%E8%8A%B1%E6%9E%9D%E7%BE%B9"
        }}>{`花枝羹`}</a>{` (湯頭)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Cong_you_bing"
        }}>{`蔥油餅`}</a>{` - Scallion pancake especially with egg`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Douhua"
        }}>{`豆花`}</a>{` - Tofu pudding`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Taiwanese_fried_chicken"
        }}>{`鹹酥雞`}</a>{` - Taiwanese fried chicken`}</li>
      <li parentName="ul">{`五味鵝蛋 - Five spice goose eggs`}</li>
      <li parentName="ul">{`仙草奶涷 - Grass jelly with condensed milk and ice`}</li>
      <li parentName="ul">{`皮蛋燒 - Fried `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Century_egg"
        }}>{`century eggs`}</a>{` with different toppings`}</li>
      <li parentName="ul">{`鹹豆漿 - Salty soy milk`}</li>
      <li parentName="ul">{`Crickets - I'm not adventurous enough for insects`}</li>
    </ul>
    <h4 {...{
      "id": "珍珠奶茶-bubble-tea"
    }}>{`珍珠奶茶 (Bubble tea)`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tw.tp-tea.com/"
        }}>{`茶湯會`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chunshuitang.com.tw/"
        }}>{`春水堂`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gong-cha.com.tw/"
        }}>{`貢茶`}</a></li>
    </ul>
    <h4 {...{
      "id": "鳳梨酥-pineapple-cake"
    }}>{`鳳梨酥 (Pineapple Cake)`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.chiate88.com.tw/"
        }}>{`佳德`}</a>{` - Chia Te Bakery, `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hU11jCoBRDxr6kfS9"
        }}>{`Taipei branch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.taipeileechi.com.tw/"
        }}>{`台北犁記`}</a>{` - Lee Chi, `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vPBGXKnCgUFak7QE7"
        }}>{`Taipei branch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.sunnyhills.com.tw/index/zh-tw/"
        }}>{`微熱山丘`}</a>{` - Sunny Hills, `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/bVnvGaUGiLeivTGq7"
        }}>{`Taipei branch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.dawncake.com.tw/"
        }}>{`日出大地的乳酪蛋糕`}</a>{` - Dawn Cake, `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Hep4RA4wH1Way5NNA"
        }}>{`Taichung branch`}</a></li>
    </ul>
    <h4 {...{
      "id": "pastries"
    }}>{`Pastries`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Suncake_(Taiwan)"
        }}>{`太陽餅`}</a>{` - Sun Cake`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://zh.wikipedia.org/zh-hans/%E8%9B%8B%E9%BB%83%E9%85%A5#:~:text=%E8%9B%8B%E9%BB%84%E9%85%A5%E6%98%AF%E5%8F%B0%E6%B9%BE%E6%9C%88%E9%A5%BC,%E5%90%83%E7%9A%84%E6%9C%88%E9%A5%BC%E4%B9%8B%E4%B8%80%E3%80%82&text=%E6%A0%B9%E6%8D%AE1986%E5%B9%B48%E6%9C%88,%E7%8A%B6%E5%85%83%E9%A5%BC%E7%9A%84%E8%BF%9B%E5%8C%96%E7%89%88%E3%80%82"
        }}>{`蛋黄酥`}</a>{` - Lotus or red bean usually with salted egg pastry`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://zh.wikipedia.org/zh/%E8%8A%8B%E9%A0%AD%E9%85%A5#:~:text=%E8%8A%8B%E9%A0%AD%E9%85%A5%E6%98%AF%E5%A4%96%E5%BD%A2%E7%82%BA,%E5%BE%8C%E4%BE%86%E6%88%90%E7%82%BA%E7%95%B6%E5%9C%B0%E4%B8%80%E7%A8%AE%E9%BB%9E%E5%BF%83%E3%80%82"
        }}>{`芋頭酥`}</a>{` - Taro pastry`}</li>
    </ul>
    <h2 {...{
      "id": "台北市-taipei"
    }}>{`台北市 (Taipei)`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/r6VM9tL1Bf36sVbf6"
        }}>{`士林夜市`}</a>{` - Shilin Night Market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/TE3oUMypm56QLqceA"
        }}>{`師大路夜市`}</a>{` - Shida Night Market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ZnbbmJ4m6TzZHNRZ9"
        }}>{`林東芳牛肉麵`}</a>{` - Lin Dong Fang Beef Noodles`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hgVT81zVX6XA9TUAA"
        }}>{`鼎泰豐`}</a>{` - Din Tai Fung has branches all over`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/f8tYUrtpnSMCSVis6"
        }}>{`THANH KY`}</a>{` - Vietnamese restaurant`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XbTv1eh3EDrWK5eM6"
        }}>{`象山`}</a>{` - Elephant mountain is the easiest hike out of the Four Beasts Mountain (四獸山) with a great night view of Taipei`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/EwyVXYosSNcN7HtJ9"
        }}>{`國立國父紀念館`}</a>{` - Sun Yat Sun Memorial`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/aWDboUeKBPX6uo496"
        }}>{`台北101觀景台`}</a>{` - Taipei 101, tallest building around`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/LGPjmbh5YhXCkctSA"
        }}>{`陽明山國家公園`}</a>{` - Yangmingshan National Park have hot springs nearby`}</li>
    </ul>
    <h4 {...{
      "id": "金瓜石-jinguashi"
    }}>{`金瓜石 (Jinguashi)`}</h4>
    <ul>
      <li parentName="ul">{`The stop after `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WM3F74jiqtKJZDSM9"
        }}>{`九份 (Jioufen)`}</a>{` is far less crowded and peaceful`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4Hqdgdq2sYKHozDa7"
        }}>{`本山五坑`}</a>{` - Benshan Fifth Tunnel`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ZdSy5euETPDoX4YYA"
        }}>{`黃金博物館`}</a>{` - Gold Museum and Gold panning experience`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vyNFD4PfLgvnzRaA8"
        }}>{`礦工食堂`}</a>{` - Miners Canteen serves "Miner’s Lunch Box"`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Vz1Xpjzny9i1tPVF6"
        }}>{`無耳茶壺山`}</a>{` - Teapot Mountain Trail`}
        <ul parentName="li">
          <li parentName="ul">{`Entrance is near the gold building but very few signage`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/5D3DwgCysWryD1aAA"
            }}>{`金瓜石神社遺址`}</a>{` - Jinguashi Shrine`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "台中市-taichung"
    }}>{`台中市 (Taichung)`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/b1TZqcwXCMxGgdFd7"
        }}>{`彩虹眷村`}</a>{` - Rainbow Village, lots of graffiti painted by a veteran soldier`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/jE1B9hXyjEYoFNCUA"
        }}>{`逢甲夜市`}</a>{` - Feng Chia Night Market... must be the biggest night market, it's massive`}</li>
    </ul>
    <h2 {...{
      "id": "花蓮-hualien"
    }}>{`花蓮 (Hualien)`}</h2>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WD9kG75bVxn2dYfDA"
        }}>{`老牌炸蛋蔥油餅`}</a>{` - Only opened midday and lines are long but move fast. You want the Scallion Pancake With Deep Fried Egg!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3GfBfQCJeAfNwju88"
        }}>{`公正包子`}</a>{` - Gong Zheng Bun Store`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/acPedZrvbz64jQSp8"
        }}>{`大紅蕃薑母鴨旗艦總店`}</a>{` - Ginger duck hot pot`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/EXPEC7EeZ3sDEV2v8"
        }}>{`廟口紅茶`}</a>{` - Breakfast`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/59yKAbqqyNgDvrKTA"
        }}>{`阿嬤ㄟ古早味`}</a>{` - Breakfast`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/KDdD25jgr6uSceBf7"
        }}>{`慕谷慕魚`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Limited parking and requires a permit. Narrow driving area.`}</li>
          <li parentName="ul">{`Lagoon area is around 5km walk`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/sjPp65j33p7k5agz5"
            }}>{`龍澗冰廠`}</a>{` - Popsicles espeically the Taro flavor (芋頭冰)!`}</li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "台11線-highway-11"
    }}>{`台11線 (Highway 11)`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/YmKrWTjvdsa6mAveA"
        }}>{`East Coast National Scenic Area`}</a>{` - Has a very `}<a parentName="li" {...{
          "href": "https://www.eastcoast-nsa.gov.tw/en/"
        }}>{`informative website`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/EKYVLHruGD6ZALxs6"
        }}>{`七星潭`}</a>{` - Qixingtan Beach, North behind the Hualien Airport`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/CnbGfo2v7im6Qe6j7"
        }}>{`牛山風景區`}</a>{` - Niushan (Cow Mountain) Beach`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/oDHr4FX2XLWoTvf96"
        }}>{`漫遊雲山水)`}</a>{` - Huge park`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/2dLpHLW3N3ZVMjEQA"
        }}>{`花蓮鯉魚潭`}</a>{` - Liyu Lake`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/V8mT1GfTH59yxWDv9"
        }}>{`芭崎休息區`}</a>{` - Baqi Gazebbo`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/umFF5dBGE1di7EgS9"
        }}>{`石梯坪`}</a>{` - Shitiping`}</li>
    </ul>
    <h4 {...{
      "id": "太魯閣-taroko-gorge"
    }}>{`太魯閣 (Taroko Gorge)`}</h4>
    <ul>
      <li parentName="ul">{`Suggest renting a car. Public transit works too but the buses weren't always on time and there wasn't a whole lot of foot traffic.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/P1CzXJ9Z3h8ppHaQ6"
        }}>{`九曲洞隧道`}</a>{` - Tunnel of Nine Turns`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/iyPg6PNXEAHhpwbUA"
        }}>{`長春祠`}</a>{` - Eternal Spring Shrine`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/J5e9DjfpwVWFmrYD7"
        }}>{`燕子口步道`}</a>{` - Swallow Grotto Trail with suspension bridge`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hp443fAxkFuohcVH7"
        }}>{`砂卡礑步道`}</a>{` - Shakadang Trail`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/v49tDTJuPEr1eyoz8"
        }}>{`天祥遊憩區`}</a>{` - Tianxiang area with `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/TDkB33dwTXFwXbAXA"
        }}>{`祥德寺 (Hsiang-Te Temple)`}</a></li>
    </ul>
    <h2 {...{
      "id": "臺南-tainan"
    }}>{`臺南 (Tainan)`}</h2>
    <p>{`One of my top food places!`}</p>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/4K2W6GCWvWS3Hra38"
        }}>{`延平市場`}</a>{` - Yanping Market`}
        <ul parentName="li">
          <li parentName="ul">{`Farmer's market with lots of food options`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/Mh5vcJDrSvtXdEVS8"
            }}>{`粿仔嬤`}</a>{` - Old shop serving 肉粿 (Meat cake)`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/tXztLUh6BsVq9jxw5"
        }}>{`花園夜市`}</a>{` - Flowers Night Market is probably the largest but crowded`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3VVfmwcgVq3BD71g8"
        }}>{`大東夜市`}</a>{` - Dadong Night Market`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8puMA1MUUVEH2Ej46"
        }}>{`Blue Print`}</a>{` - Iconic street art`}</li>
    </ul>
    <h4 {...{
      "id": "west-central-district"
    }}>{`West Central District`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wjPPrp2uqJRdZgVY7"
        }}>{`吳園藝文中心`}</a>{` - Tainan Wu Garden`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4jLnWCRfk8JQaNvg7"
        }}>{`神農街`}</a>{` - Shennong street is preserved from the Qing dynasty`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/yNaniJeRHfMBmP9D9"
        }}>{`臺南孔廟`}</a>{` - Tainan Confucius Temple`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/G8sjijJ6ZX8RuBm56"
        }}>{`赤崁樓`}</a>{` - Chihkan Tower`}</li>
    </ul>
    <h4 {...{
      "id": "excursions"
    }}>{`Excursions`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/k3bjmts3FC5SanEY7"
        }}>{`正統鹿耳門聖母廟`}</a>{` - Luermen Temple`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7dwckWULRSKKQVa18"
        }}>{`七股鹽山`}</a>{` - Qigu Salt Mountain and the `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/w5tWgQuW42t2LEaJA"
        }}>{`臺灣鹽博物館 (Salt Museum)`}</a>{` next to it`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      